import { ProductModel } from './../models/ProductModel';
import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import RequestModel from '../models/RequestModel';
import CouponModel from '../models/CouponModel';
import PackageModel from '../models/PackageModel';
import { ProductType } from '../models/ProductModel';

interface ProductDetailState {
    open: boolean,
    isPopupMode: boolean,
    selectedProduct: RequestModel | CouponModel | PackageModel | ProductModel | undefined,
    productType: ProductType,
    isAddMode: boolean
}

const initialState: ProductDetailState = {
    open: false,
    isPopupMode: false,
    selectedProduct: undefined,
    productType: ProductType.Request,
    isAddMode: false
}

export const ProductDetailSlice = createSlice({
    name: "ProductDetail",
    initialState,
    reducers: {
        setProductDetailOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setIsPopupMode: (state, action: PayloadAction<boolean>) => {
            state.isPopupMode = action.payload;
        },
        setSelectedProduct: (state, action: PayloadAction<RequestModel | CouponModel | PackageModel | ProductModel | undefined>) => {
            state.selectedProduct = action.payload;
        },
        setProductType: (state, action: PayloadAction<ProductType>) => {
            state.productType = action.payload;
        },
        setIsAddMode: (state, action: PayloadAction<boolean>) => {
            state.isAddMode = action.payload;
        },
    }
});

export const ProductDetailSelector = createSelector(
    (state: RootState) => state.productDetail,
    (data) => data
);

export const {
    setProductDetailOpen,
    setSelectedProduct,
    setIsPopupMode,
    setProductType,
    setIsAddMode
} = ProductDetailSlice.actions;

export default ProductDetailSlice.reducer;