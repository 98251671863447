import React, { useEffect } from 'react';
import { Button, Container } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { useTranslation } from 'react-i18next';
import Datatable from '../Datatable/Datatable';
import { setAboutHtml, setDashboardHtml, StaticHtmlSelector } from './reducers/StaticHtmlReducer';
import StaticHtmlApi, { StaticHtmlResponse } from './api/StaticHtmlApi';
import Utility from '../../app/utility';

const About: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const staticHtmlSelector = useSelector(StaticHtmlSelector);
    const userProfile = useSelector(userProfileSelector);

    useEffect(() => {
        document.title = t("About")

        loadHtml();
    }, []);

    const loadHtml = () => {
        StaticHtmlApi.GetAbout()
            .then((response: StaticHtmlResponse) => {
                dispatch(setAboutHtml(response));
            })
    };

    return <div
        dangerouslySetInnerHTML={{ __html: Utility.getStaticHtml(staticHtmlSelector.aboutHtml, userProfile.Lang === "en" ? "en" : "vi") }}
    >
    </div>
};

export default About;