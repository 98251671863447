import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProductSelector } from './reducers/StoreReducer';
import { ProductType } from './models/ProductModel';
import RequestModel from './models/RequestModel';
import PackageModel from './models/PackageModel';
import CouponModel from './models/CouponModel';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { Button } from '@material-ui/core';
import { ProductDetailSelector, setIsAddMode, setIsPopupMode, setProductDetailOpen, setProductType, setSelectedProduct } from './reducers/ProductDetailReducer';
import Utility from '../../app/utility';
import _ from 'lodash';

interface ProductListProp {
    productType: ProductType
}

const ProductList: React.FunctionComponent<ProductListProp> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector(ProductSelector);
    const useProfile = useSelector(userProfileSelector);
    const productDetail = useSelector(ProductDetailSelector);

    const getProducts = (): RequestModel[] | PackageModel[] | CouponModel[] | undefined => {
        switch (props.productType) {
            case ProductType.Coupon:
                return store.coupons;

            case ProductType.Package:
                return store.packages;

            case ProductType.Request:
                return store.requests;
        }
    };

    const isBuyable = (productId: string) => {
        return _.some(store.buyableProducts, (buyableProductId) => {
            return buyableProductId === productId
        });
    };

    return (
        <>
            {
                !(productDetail.open && !productDetail.isPopupMode) &&
                <div className="product-list">
                    {(getProducts() as Array<RequestModel | PackageModel | CouponModel>).map((product: RequestModel | PackageModel | CouponModel) => (
                        <div className="product-item" key={product.id}>
                            <div className="product-item-icon">
                                <img src={product.icon}></img>
                            </div>
                            <div className="product-item-body">
                                <div className="product-item-body-name">
                                    {Utility.getProductDisplay(product.display, "name", useProfile.Lang)}
                                </div>
                                <div className="product-item-body-price">
                                    {Utility.getProductDisplay(product.display, "price", useProfile.Lang)}
                                </div>
                                <div className="product-item-body-des" dangerouslySetInnerHTML={{ __html: Utility.getProductDisplay(product.display, "des", useProfile.Lang) }}></div>
                                <div className="product-item-body-button-detail">
                                    <Button
                                        onClick={o => {
                                            dispatch(setSelectedProduct(product));
                                            dispatch(setProductType(props.productType));
                                            dispatch(setProductDetailOpen(true));

                                            if (props.productType === ProductType.Request) {
                                                dispatch(setIsPopupMode(false))
                                                dispatch(setIsAddMode(true))
                                            } else {
                                                dispatch(setIsPopupMode(true))
                                            }
                                        }}
                                        disabled={!isBuyable(product.id)}
                                    >
                                        {t("DetailButton")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    )
};

export default ProductList;