import { ProductDisplayDetailModel } from './../components/Store/models/ProductModel';
import Cookies from 'js-cookie';
import config from '../config.json'
import i18n from './i18n';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ResourceApi, { ResourceModel, GetResourcesParam } from './resourceApi';
import { ProductDisplayModel } from '../components/Store/models/ProductModel';
import { StaticHtmlResponse } from '../components/Dashboard/api/StaticHtmlApi';

const swal = withReactContent(Swal);

const Utility = {
    getQueryStringByName(name: string) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name)
    },

    Parse_Phone(value: string) {
        var output = false;
        // Trim blank & separators ( (, ), -, _, . )
        value = value.trim();
        value = value.replace(/\s/g, "").replace("(", "").replace(")", "").replace("-", "").replace("_", "").replace(".", "");

        // Convert to start = 0
        if (value.substring(0, 2) == "84") {
            value = "0" + value.substring(2, value.length);
        }

        // Convert to 10 digit 
        var ruleset = [
            { from: '0120', to: '070' },
            { from: '0121', to: '079' },
            { from: '0122', to: '077' },
            { from: '0126', to: '076' },
            { from: '0128', to: '078' },
            { from: '0124', to: '084' },
            { from: '0127', to: '081' },
            { from: '0129', to: '082' },
            { from: '0123', to: '083' },
            { from: '0125', to: '085' },
            { from: '016', to: '03' },
            { from: '018', to: '05' },
            { from: '019', to: '05' }
        ];

        if (value.length == 11) {
            ruleset.forEach((item) => {
                if (value.indexOf(item.from) == 0) {
                    value = item.to + value.substring((parseInt(item.to.length.toString()) + 1), value.length);
                }
            })
        }

        // Validate phone
        var avail_phone = ['086', '096', '097', '098', '032', '033', '034', '035', '036', '037', '038', '039', '089', '090', '093', '070', '079', '077', '076', '078', '088', '091', '094', '083', '084', '085', '081', '082', '092', '056', '058', '099', '059', '087'];

        if (value.length == 10) {
            avail_phone.forEach(item => {
                if (value.indexOf(item) == 0) {
                    output = true;
                }
            });
        }

        return output;
    },
    validate: {
        Notblank: function (value: string) {
            if (!(value.length > 0)) {
                throw i18n.t("Value could not be blank");
            }
        },

        Email: function (value: string) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(value)) {
                throw i18n.t("Please input correct email");
            }
        },

        Gmail: function (value: string) {
            this.Email(value);

            if (!(value.indexOf('@gmail.com') > 0)) {
                throw i18n.t("Please input correct gmail");
            }
        },

        Phone: function (value: string) {
            if (!Utility.Parse_Phone(value)) {
                throw i18n.t("Please input correct phone number")
            }
        }
    },

    async getLanguage(): Promise<string> {
        let lang = Cookies.get("lang");
        if (lang && lang.length > 0) {
            return lang;
        }

        const response = await fetch(config.locationDetectedEndPoint);
        const responseText = await response.text();

        const locIndex = responseText.indexOf("loc=");
        lang = responseText.substr(locIndex + 4, 2);

        return lang.toLowerCase() === "vn" ? "vi" : "en";
    },

    async getResource(lang: string, keys: string[]): Promise<ResourceModel[]> {
        const response = await ResourceApi.GetResources({
            Lang: lang,
            Keys: keys.join(",")
        } as GetResourcesParam);

        if (response.is_success) {
            return response.data as ResourceModel[]
        } else {
            throw response.exception;
        }
    },

    Alert: {
        Error: function (msg: string | unknown) {
            return swal.fire({ icon: 'error', title: 'Oops...', text: (msg as string) });
        },
        Success: function (msg: string | unknown) {
            return swal.fire({ icon: 'success', title: (msg as string), showConfirmButton: false, timer: 1500 });
        },
        Info: function (msg: string | unknown) {
            return swal.fire({ icon: 'info', title: (msg as string), showConfirmButton: false, timer: 1500 });
        },
        // Warning: function (msg, callBack) { swal.fire({ icon: 'warning', title: msg, showConfirmButton: false, timer: 1500 }).then(() => { if (callBack) { callBack(); } }); },
        // Confirm: function (msg, callBack) { swal.fire({ title: msg, icon: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Yes' }).then((result) => { if (result.isConfirmed) { callBack(result); } }) },
    },

    DateFormat: {
        getDateDDMMYYYY: (dateTime: number) => {
            const date = new Date(dateTime);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        },
        getTime: (dateTime: number) => {
            const date = new Date(dateTime);
            const hour = date.getHours() < 10 ? "0" + date.getHours().toString() : date.getHours().toString();
            const minute = date.getMinutes() < 10 ? "0" + date.getMinutes().toString() : date.getMinutes().toString();
            const second = date.getSeconds() < 10 ? "0" + date.getSeconds().toString() : date.getSeconds().toString();

            return hour + ":" + minute + ":" + second;
        }
    },

    CloneObject: (obj: any) => {
        return JSON.parse(JSON.stringify(obj));
    },

    getProductDisplay: (display: ProductDisplayModel, fieldName: keyof ProductDisplayDetailModel, lang: string) => {
        let key: keyof ProductDisplayModel;
        for (key in display) {
            if (key.toString() === lang) {
                return display[key][fieldName];
            }
        }

        return "";
    },
    getStaticHtml: (display: StaticHtmlResponse | null, lang: keyof StaticHtmlResponse) => {
        if (display) {
            let key: keyof StaticHtmlResponse;
            for (key in display) {
                if (key.toString() === lang) {
                    return display[key];
                }
            }

            return "";
        }
        return "";
    },
    getServiceType: (service_id: string): string => {
        if (service_id.indexOf("coupon") === 0) {
            return "Coupon";
        }

        if (service_id.indexOf("package") === 0) {
            return "Package";
        }

        return "Gift";
    },
    getCurrentStatusValue: (start: number, end: number): number => {
        let now = (new Date()).getTime();
        return (now - start) / (end - start) * 100;
    }
}

export default Utility;