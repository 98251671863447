import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import UserProfile from "../models/UserProfileModel";

const initialState: UserProfile = {
    Token: "",
    Lang: "en",
}

export const UserProfileSlice = createSlice({
    name: "UserProfile",
    initialState,
    reducers: {
        setLang: (state, action: PayloadAction<string>) => {
            state.Lang = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.Token = action.payload;
        },
    }
});

export const userProfileSelector = createSelector(
    (state: RootState) => state.userProfile,
    (data) => data
);

export const { setLang, setToken } = UserProfileSlice.actions;

export default UserProfileSlice.reducer;