import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import './Payment.scss';
import { useTranslation } from 'react-i18next';
import Utility from '../../app/utility';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { ResourceModel } from '../../app/resourceApi';
import PaymentApi from './api/PaymentApi';
import ejs from 'ejs';
import {
    paymentUISelector,
    setCode,
    setIsGettingCode,
    setIsPaymentCodeMapped,
    setIsTemplateLoaded,
    setPayment_BankTransfer_Html,
    setPayment_BankTransfer_Template,
    setPayment_MoMo_Html,
    setPayment_MoMo_Template,
    setPayment_Note_Html,
    setPayment_VietCapitalBank_Html,
    setPayment_VietCapitalBank_Template
} from './reducer/PaymentUIReducer';
import PaymentLogTable from './PaymentLogsTable';

const Payment: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const userProfile = useSelector(userProfileSelector);
    const paymentUI = useSelector(paymentUISelector);
    const dispatch = useDispatch();

    const getPaymentCode = () => {
        if (paymentUI.Code.length === 0 && !paymentUI.IsGettingCode) {
            dispatch(setIsGettingCode(true))

            PaymentApi.GetKey()
                .then((response) => {
                    const key = "R:" + response.data;
                    dispatch(setCode(key))
                    dispatch(setIsGettingCode(false))
                });
        }

        if (paymentUI.IsTemplateLoaded && !paymentUI.IsPaymentCodeMapped && paymentUI.Code.length > 0) {
            let html = ejs.render(paymentUI.Payment_BankTransfer_Template, {
                Code: paymentUI.Code
            });
            dispatch(setPayment_BankTransfer_Html(html));

            html = ejs.render(paymentUI.Payment_MoMo_Template, {
                Code: paymentUI.Code
            });
            dispatch(setPayment_MoMo_Html(html));

            html = ejs.render(paymentUI.Payment_VietCapitalBank_Template, {
                Code: paymentUI.Code
            });
            dispatch(setPayment_VietCapitalBank_Html(html));

            dispatch(setIsPaymentCodeMapped(true))

        }
    };

    getPaymentCode();

    useEffect(() => {
        document.title = t("Payment")

        getResource();
    }, []);

    const getResource = () => {
        Utility.getResource(userProfile.Lang, [
            "Payment_BankTransfer.html",
            "Payment_VietCapitalBank.html",
            "Payment_MoMo.html",
            "Payment_Note.html",
        ])
            .then((resources: ResourceModel[]) => {
                resources.forEach((resource: ResourceModel) => {
                    switch (resource.key) {
                        case "Payment_BankTransfer.html":
                            dispatch(setPayment_BankTransfer_Template(resource.html))
                            break;
                        case "Payment_VietCapitalBank.html":
                            dispatch(setPayment_VietCapitalBank_Template(resource.html))
                            break;
                        case "Payment_MoMo.html":
                            dispatch(setPayment_MoMo_Template(resource.html))
                            break;
                        case "Payment_Note.html":
                            dispatch(setPayment_Note_Html(resource.html))
                            break;
                        default:
                            break;
                    }
                });

                dispatch(setIsTemplateLoaded(true))
            });
    };

    return (
        <div id="payment">
            <Accordion defaultExpanded={true} className="payment-block-do-not-collapse">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{t("Bank transfer with this verify code")}</Typography>
                </AccordionSummary>
                <AccordionDetails className="payment-detail-block">
                    <div className="payment-code">
                        <span onClick={() => {
                            navigator.clipboard.writeText(paymentUI.Code);
                            Utility.Alert.Info(t("Code is copied to clipboard"))
                        }}
                        >
                            {paymentUI.Code}
                        </span>

                        <Button
                            className="payment-code-refresh"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setCode(""));
                                dispatch(setIsPaymentCodeMapped(false));
                            }}
                        >
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </Button>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: paymentUI.Payment_BankTransfer_Html }}>
                    </div>
                    <hr />
                    <div>
                        <b>* {t("Receiver's information")}:</b>
                    </div>
                    <br />
                    <div>
                        <div>
                            <AccountBalanceIcon></AccountBalanceIcon>
                            {t("Viet Capital Bank Name")}
                        </div>
                        <div className='payment-detail-block'>
                            <div dangerouslySetInnerHTML={{ __html: paymentUI.Payment_VietCapitalBank_Html }}>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <PaymentLogTable></PaymentLogTable>
        </div>
    )
};

export default Payment;