import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";
import config from "../../../config.json";
import PaymentLogModel from "../models/PaymentLogModel";

export interface GetLogsParam {
    pc?: string
}

const PaymentApi = {
    GetKey() {
        return Api.get<unknown, BaseResponse<string>>(config.userEndpoint + "/payment/genkey", null);
    },
    GetLogs(param: GetLogsParam) {
        return Api.get<GetLogsParam, BaseResponse<PaymentLogModel[]>>(config.userEndpoint + "/transaction/list", param);
    },
};
export default PaymentApi;