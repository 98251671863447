import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PaymentUIState {
    IsTemplateLoaded: boolean,
    IsPaymentCodeMapped: boolean,
    Payment_BankTransfer_Template: string,
    Payment_BankTransfer_Html: string,
    Payment_VietCapitalBank_Template: string,
    Payment_VietCapitalBank_Html: string,
    Payment_MoMo_Template: string,
    Payment_MoMo_Html: string,
    Payment_Note_Html: string,
    Code: string,
    IsGettingCode: boolean
}

const initialState: PaymentUIState = {
    IsTemplateLoaded: false,
    IsPaymentCodeMapped: false,
    Payment_BankTransfer_Template: "",
    Payment_BankTransfer_Html: "",
    Payment_VietCapitalBank_Template: "",
    Payment_VietCapitalBank_Html: "",
    Payment_MoMo_Template: "",
    Payment_MoMo_Html: "",
    Payment_Note_Html: "",
    Code: "",
    IsGettingCode: false
}

export const PaymentUISlice = createSlice({
    name: "PaymentUI",
    initialState,
    reducers: {
        setIsTemplateLoaded: (state, action: PayloadAction<boolean>) => {
            state.IsTemplateLoaded = action.payload;
        },
        setIsPaymentCodeMapped: (state, action: PayloadAction<boolean>) => {
            state.IsPaymentCodeMapped = action.payload;
        },
        setPayment_BankTransfer_Template: (state, action: PayloadAction<string>) => {
            state.Payment_BankTransfer_Template = action.payload;
        },
        setPayment_BankTransfer_Html: (state, action: PayloadAction<string>) => {
            state.Payment_BankTransfer_Html = action.payload;
        },
        setPayment_VietCapitalBank_Template: (state, action: PayloadAction<string>) => {
            state.Payment_VietCapitalBank_Template = action.payload;
        },
        setPayment_VietCapitalBank_Html: (state, action: PayloadAction<string>) => {
            state.Payment_VietCapitalBank_Html = action.payload;
        },
        setPayment_MoMo_Template: (state, action: PayloadAction<string>) => {
            state.Payment_MoMo_Template = action.payload;
        },
        setPayment_MoMo_Html: (state, action: PayloadAction<string>) => {
            state.Payment_MoMo_Html = action.payload;
        },
        setPayment_Note_Html: (state, action: PayloadAction<string>) => {
            state.Payment_Note_Html = action.payload;
        },
        setCode: (state, action: PayloadAction<string>) => {
            state.Code = action.payload;
        },
        setIsGettingCode: (state, action: PayloadAction<boolean>) => {
            state.IsGettingCode = action.payload;
        },
    }
});

export const paymentUISelector = createSelector(
    (state: RootState) => state.paymentUI,
    (data) => data
);

export const {
    setIsTemplateLoaded,
    setIsPaymentCodeMapped,
    setPayment_BankTransfer_Template,
    setPayment_BankTransfer_Html,
    setPayment_VietCapitalBank_Template,
    setPayment_VietCapitalBank_Html,
    setPayment_MoMo_Template,
    setPayment_MoMo_Html,
    setPayment_Note_Html,
    setCode,
    setIsGettingCode
} = PaymentUISlice.actions;

export default PaymentUISlice.reducer;