import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import SubscriptionModel from '../models/SubscriptionModel';

interface MySubscriptionsState {
    isDataLoaded: boolean,
    subscriptionsLeft: SubscriptionModel[],
    currentSubscriptions: SubscriptionModel[],
}

const initialState: MySubscriptionsState = {
    isDataLoaded: false,
    subscriptionsLeft: [],
    currentSubscriptions: []
}

export const MySubscriptionsSlice = createSlice({
    name: "MySubscriptions",
    initialState,
    reducers: {
        setIsDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.isDataLoaded = action.payload;
        },
        setSubscriptionsLeft: (state, action: PayloadAction<SubscriptionModel[]>) => {
            state.subscriptionsLeft = action.payload;
        },
        setCurrentSubscriptions: (state, action: PayloadAction<SubscriptionModel[]>) => {
            state.currentSubscriptions = action.payload;
        },
    }
});

export const MySubscriptionsSelector = createSelector(
    (state: RootState) => state.mySubscriptions,
    (data) => data
);

export const {
    setIsDataLoaded,
    setSubscriptionsLeft,
    setCurrentSubscriptions
} = MySubscriptionsSlice.actions;

export default MySubscriptionsSlice.reducer;