import { useHistory } from "react-router-dom";

const Logout: React.FunctionComponent = () => {
    const cookieDate = (d: Date): string => {
        function d2(n: number) { return n < 10 ? '0' + n : n; }
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return '' +
            days[d.getUTCDay()] + ', ' +
            d2(d.getUTCDate()) + '-' +
            months[d.getUTCMonth()] + '-' +
            d.getUTCFullYear() + ' ' +
            d2(d.getUTCHours()) + ':' +
            d2(d.getUTCMinutes()) + ':' +
            d2(d.getUTCSeconds()) + ' GMT';
    }

    const expireDate = new Date();

    document.cookie = "lang=; expires=" + cookieDate(expireDate) + "; path=/; domain=.resys.org;";
    document.cookie = "room_id=; expires=" + cookieDate(expireDate) + "; path=/; domain=.resys.org;";
    document.cookie = "token=; expires=" + cookieDate(expireDate) + "; path=/; domain=.resys.org;";

    window.location.href = "/login";

    return (
        <>

        </>
    )
};

export default Logout;