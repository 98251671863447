import { Type } from "typescript";

export interface ProductModel {
    id: string,
    auto_renewal: boolean,
    in_store: boolean,
    trigger_type: string,
    icon: string,
    bulk_order: boolean,
    display: ProductDisplayModel
    valid: ProductValidModel,
    price: number,
}

export interface ProductDisplayModel {
    vi: ProductDisplayDetailModel,
    en: ProductDisplayDetailModel
}

export interface ProductDisplayDetailModel {
    name: string,
    des: string,
    full_des: string,
    price: string
}

export interface ProductValidModel {
    from: number,
    to: number,
}

export enum ProductType {
    System,
    Coupon,
    Gift,
    Package,
    Request
}