import { StaticHtmlResponse } from './../../Dashboard/api/StaticHtmlApi';
import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"

interface StaticHtmlState {
    dashboardHtml: StaticHtmlResponse | null,
    aboutHtml: StaticHtmlResponse | null,
}

const initialState: StaticHtmlState = {
    dashboardHtml: null,
    aboutHtml: null
}

export const StaticHtmlSlice = createSlice({
    name: "Store",
    initialState,
    reducers: {
        setDashboardHtml: (state, action: PayloadAction<StaticHtmlResponse>) => {
            state.dashboardHtml = action.payload;
        },
        setAboutHtml: (state, action: PayloadAction<StaticHtmlResponse>) => {
            state.aboutHtml = action.payload;
        },
    }
});

export const StaticHtmlSelector = createSelector(
    (state: RootState) => state.staticHtml,
    (data) => data
);

export const {
    setDashboardHtml,
    setAboutHtml,
} = StaticHtmlSlice.actions;

export default StaticHtmlSlice.reducer;