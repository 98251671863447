import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import { ProductDetailSelector, setProductDetailOpen } from './reducers/ProductDetailReducer';
import Utility from '../../app/utility';
import StoreApi, { CheckParam, StoreUrl } from './api/StoreApi';
import BaseResponse from '../../app/BaseResponse';
import { ProductType } from './models/ProductModel';
import { setCheckoutOpen, setCheckOutOptions } from './reducers/CheckOutPopupReducer';
import CheckOutOptionModel from './models/CheckOutOptionModel';

const ProductDetailPopup: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const productDetail = useSelector(ProductDetailSelector);
    const useProfile = useSelector(userProfileSelector);
    const [productCount, setProductCount] = useState(1);

    useEffect(() => {
    }, []);

    const submit = () => {
        let param = {
            sid: productDetail.selectedProduct?.id,
            quantity: productCount,
            data: ""
        } as CheckParam;
        StoreApi.Check(param)
            .then((response: BaseResponse<CheckOutOptionModel[]>) => {
                if (response.is_success) {
                    dispatch(setCheckOutOptions(response.data));
                    dispatch(setCheckoutOpen(true));
                    dispatch(setProductDetailOpen(false));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            }).catch(error => {
                Utility.Alert.Error(error);
            })
    };

    return (
        <>
            <Dialog
                open={productDetail.open && productDetail.isPopupMode}
                onClose={o => {
                    dispatch(setProductDetailOpen(false))
                }}
                className="product-detail-dialog"
            >
                <DialogContent
                    className="product-detail-dialog-inner"
                >
                    <div className="product-detail">
                        <div className="product-detail-icon">
                            <img src={productDetail.selectedProduct?.icon}></img>
                        </div>
                        <div className="product-detail-body">
                            <div className="product-detail-body-name">
                                {
                                    productDetail.selectedProduct &&
                                    Utility.getProductDisplay(productDetail.selectedProduct.display, "name", useProfile.Lang)
                                }
                            </div>
                            <div className="product-detail-body-price">
                                {
                                    productDetail.selectedProduct &&
                                    Utility.getProductDisplay(productDetail.selectedProduct.display, "price", useProfile.Lang)
                                }
                            </div>
                            {
                                productDetail.selectedProduct?.bulk_order &&
                                <div className="product-detail-body-quantity">
                                    <span>{t("Quantity") + ":"}</span>
                                    <span
                                        className="product-detail-body-quantity-button"
                                        onClick={() => {
                                            if (productCount > 1) {
                                                setProductCount(productCount - 1);
                                            }
                                        }}
                                    >
                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span className="product-detail-body-quantity-number">
                                        <TextField
                                            value={productCount}
                                            variant="outlined"
                                            onChange={(e) => {
                                                let numberValue = parseInt(e.target.value);
                                                if (!isNaN(numberValue) && numberValue > 0) {
                                                    setProductCount(numberValue);
                                                }
                                            }}
                                        >
                                        </TextField>
                                    </span>
                                    <span
                                        className="product-detail-body-quantity-button"
                                        onClick={() => {
                                            setProductCount(productCount + 1);
                                        }}
                                    >
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </div>
                            }
                            <div className="product-detail-body-button-detail">
                                <Button
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    {t("Apply")}
                                </Button>
                            </div>
                            {
                                productDetail.selectedProduct &&
                                <div className="product-detail-body-des" dangerouslySetInnerHTML={{ __html: Utility.getProductDisplay(productDetail.selectedProduct.display, "full_des", useProfile.Lang) }}></div>
                            }
                        </div>
                    </div>
                    <div className="product-detail-close">
                        <Button
                            onClick={() => {
                                dispatch(setProductDetailOpen(false))
                            }}
                        >
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
};

export default ProductDetailPopup;