import _ from "lodash";
import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";
import SubscriptionModel from "../models/SubscriptionModel";
import CheckOutOptionModel from "../models/SubscriptionModel";

const axiosConfig = {
    headers: {
        ignoreCredentials: true
    }
};

const MySubscriptionsApi = {
    GetMySubscriptions() {
        return Api.get<null, BaseResponse<SubscriptionModel[]>>("/order/getusersubscriptions", null);
    },
};

export default MySubscriptionsApi;