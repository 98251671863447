import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import './css/mySubscription.scss'
import _ from 'lodash';
import { PackageType } from './models/SubscriptionModel';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
import MySubscriptions from './MySubscriptions';

const MyPackages: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(PackageType.Subscription.toString());

    useEffect(() => {
        document.title = t("My Packages");
    }, []);

    return (
        <>
            <TabContext value={selectedTab}>
                <TabList
                    className="store-tab-list"
                    indicatorColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        setSelectedTab(newValue);
                    }}>
                    <Tab className="store-tab" label={t("My discounts")} value={PackageType.Subscription.toString()} />
                    <Tab className="store-tab" label={t("My requests")} value={PackageType.Request.toString()} />
                </TabList>
                <div className="store-tab-panel package-tab-panel">
                    <TabPanel value={PackageType.Subscription.toString()} className="inner-panel">
                        <MySubscriptions></MySubscriptions>
                    </TabPanel>
                    {/* <TabPanel value={PackageType.Request.toString()}>
                        
                    </TabPanel> */}
                </div>
            </TabContext>
        </>
    )
};

export default MyPackages;