import axios from "axios";

const post = <ParamType, ResponseType>(url: string, param: ParamType, config?: any) => {
    return axios.post<ResponseType>(url, JSON.stringify(param), config)
        .then(response => response.data);
};

const get = <ParamType, ResponseType>(url: string, param: ParamType, config?: any) => {
    let requestUrl = url;

    if (param) {
        requestUrl += "?";

        for (let key in param) {
            requestUrl += key + "=" + param[key] + "&";
        }

        //trim end "&"
        requestUrl = requestUrl.substring(0, requestUrl.length - 1);
    }

    return axios.get<ResponseType>(requestUrl, config)
        .then(response => response.data);
};

const Api = {
    post, get
};

export default Api;