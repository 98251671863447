import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import { ProductDetailSelector, setProductDetailOpen } from './reducers/ProductDetailReducer';
import Utility from '../../app/utility';
import { CheckOutPopupSelector, setCheckoutOpen } from './reducers/CheckOutPopupReducer';
import CheckOutOptionModel from './models/CheckOutOptionModel';
import StoreApi, { CheckOutParam } from './api/StoreApi';
import BaseResponse from '../../app/BaseResponse';

const CheckoutPopup: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const productDetail = useSelector(ProductDetailSelector);
    const checkOutPopup = useSelector(CheckOutPopupSelector);
    const useProfile = useSelector(userProfileSelector);

    useEffect(() => {
    }, []);

    const checkout = (checkoutOption: CheckOutOptionModel) => {
        let param = {
            OptId: checkoutOption.Id
        } as CheckOutParam;

        StoreApi.CheckOut(param)
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    Utility.Alert.Success(response.msg);

                    dispatch(setCheckoutOpen(false));
                } else {
                    Utility.Alert.Error(response.exception);
                    dispatch(setCheckoutOpen(false));
                }
            }).catch(error => {
                Utility.Alert.Error(error);
                dispatch(setCheckoutOpen(false));
            })
    };

    const renderItem = (checkoutOption: CheckOutOptionModel): JSX.Element => {
        return (
            <div className="checkout-option">
                <div className="checkout-option-left">
                    <div className="checkout-option-title">
                        {
                            checkoutOption.IsDefault ?
                                t("No discount code applied") :
                                (t("Apply discount code") + " : " + checkoutOption.DiscountKey)
                        }
                    </div>
                    <div className="checkout-option-description">
                        {
                            checkoutOption.IsDefault ?
                                t("Discounts have been applied according to the activated package on your account") :
                                checkoutOption.Description
                        }
                    </div>
                    <div className="checkout-option-date">
                        ({
                            checkoutOption.IsDefault ?
                                t("Default") :
                                (t("Exp") + ": " + Utility.DateFormat.getDateDDMMYYYY(checkoutOption.Expired))
                        })
                    </div>
                </div>
                <div className="checkout-option-right">
                    <div className="checkout-option-price">
                        {
                            checkoutOption.FinalPrice.toLocaleString("vi-VN") + " đ"
                        }
                    </div>
                    <div className="checkout-option-checkout">
                        <Button
                            className="checkout-option-checkout-button"
                            onClick={() => {
                                checkout(checkoutOption);
                            }}
                        >
                            {t("Pay")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog
                open={checkOutPopup.open}
                onClose={o => {
                    dispatch(setCheckoutOpen(false))
                }}
                className="checkout-dialog"
                maxWidth="lg"
            >
                <DialogContent
                    className="checkout-dialog-inner"
                >
                    <div className="checkout-product-name">
                        {
                            productDetail.selectedProduct &&
                            Utility.getProductDisplay(productDetail.selectedProduct.display, "name", useProfile.Lang)
                        }
                    </div>
                    <div className="checkout-hint-text">
                        {t("Please choose a payment option for this order")}
                    </div>
                    <hr />
                    <div className="checkout-options">
                        {
                            checkOutPopup.checkOutOptions.length > 0 &&
                            checkOutPopup.checkOutOptions.map((checkoutOption: CheckOutOptionModel) => {
                                return renderItem(checkoutOption);
                            })
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
};

export default CheckoutPopup;