import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";
import config from "../../../config.json";

export interface LoginParam {
    email: string,
    password: string
}

export interface RegisterParam {
    email: string,
    phone: string,
    lang: string
}

export interface LoginResponse {
    username: string,
    lang: string,
    token: string
}

const LoginApi = {
    Login(param: LoginParam) {
        return Api.post<LoginParam, BaseResponse<LoginResponse>>(config.userEndpoint + "/auth/login", param);
    },
    Register(param: RegisterParam) {
        return Api.post<RegisterParam, BaseResponse<LoginResponse>>(config.userEndpoint + "/auth/register", param);
    }
};
export default LoginApi;