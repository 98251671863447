import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { ProductDetailSelector, setProductDetailOpen } from './reducers/ProductDetailReducer';
import { Button, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Utility from '../../app/utility';
import DatatableColumnModel, { DatatableColumnAlign } from '../Datatable/DatatableColumnModel';
import { ProductModel } from './models/ProductModel';
import Datatable, { PagingTypeEnum } from '../Datatable/Datatable';

const ProductDetail: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const productDetail = useSelector(ProductDetailSelector);
    const useProfile = useSelector(userProfileSelector);

    useEffect(() => {

    }, []);

    const datatableCollumnConfig = [
        {
            Field: "valid_from",
            Title: t("Start Date"),
            Align: DatatableColumnAlign.Left,
            Width: "100px",
            Render: (rowData: ProductModel) => (
                <>
                    {Utility.DateFormat.getDateDDMMYYYY(rowData.valid.from)}
                </>
            )
        } as DatatableColumnModel<ProductModel>,
        {
            Field: "valid_to",
            Title: t("Expire Date"),
            Align: DatatableColumnAlign.Left,
            Width: "100px",
            Render: (rowData: ProductModel) => (
                <>
                    {Utility.DateFormat.getDateDDMMYYYY(rowData.valid.to)}
                </>
            )
        } as DatatableColumnModel<ProductModel>,
        {
            Field: "valid_to",
            Title: t("Status"),
            Align: DatatableColumnAlign.Left,
            Width: "300px",
            Render: (rowData: ProductModel) => (
                <>
                    <LinearProgress variant="determinate" value={Utility.getCurrentStatusValue(rowData.valid.from, rowData.valid.to)} />
                </>
            )
        } as DatatableColumnModel<ProductModel>
    ];

    return (
        <>
            {
                productDetail.open && !productDetail.isPopupMode && productDetail.selectedProduct &&
                <>
                    <div
                        className="product-detail-wrapper"
                    >
                        <Button
                            variant="outlined"
                            onClick={o => {
                                dispatch(setProductDetailOpen(false));
                            }}>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>&nbsp;{t("Back")}
                        </Button>
                        {
                            //for add request
                            productDetail.isAddMode &&
                            <div>
                                <Button
                                    className="send-request-button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={o => {

                                    }}>
                                    <i className="fa fa-upload" aria-hidden="true"></i>&nbsp;{t("Send Request")}
                                </Button>
                                <div className="product-detail-content">
                                    <div className="product-detail-content-title">
                                        {Utility.getProductDisplay(productDetail.selectedProduct.display, "name", useProfile.Lang)}
                                    </div>
                                    <div className="product-detail-content-desc" dangerouslySetInnerHTML={{ __html: Utility.getProductDisplay(productDetail.selectedProduct.display, "full_des", useProfile.Lang) }}>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            //for view detail only
                            !productDetail.isAddMode &&
                            <span>
                                <span className="product-detail-name">
                                    {Utility.getProductDisplay(productDetail.selectedProduct.display, "name", useProfile.Lang)}
                                </span>
                                <span className="product-detail-type">
                                    ({t("Discount Type") + ": " + t(Utility.getServiceType(productDetail.selectedProduct.id))})
                                </span>
                            </span>
                        }
                        <div className="product-detail-table">
                            <Datatable<ProductModel>
                                useDefaultSearchBar={false}
                                pagingType={PagingTypeEnum.ShowMore}
                                data={[productDetail.selectedProduct]}
                                useItemPerPage={false}
                                columns={datatableCollumnConfig}
                                isDataLoaded={true}
                                showMoreMode_IsLastPage={true}
                            ></Datatable>
                        </div>
                        <div className="product-detail-description" dangerouslySetInnerHTML={{ __html: Utility.getProductDisplay(productDetail.selectedProduct.display, "full_des", useProfile.Lang) }}>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default ProductDetail;