import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import UserProfile from "../models/UserProfileModel";

export interface loginState {
    email: string,
    password: string,
    phone: string
}

const initialState: loginState = {
    email: "",
    password: "",
    phone: ""
}

export const LoginSlice = createSlice({
    name: "Login",
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
    }
});

export const loginSelector = createSelector(
    (state: RootState) => state.login,
    (data) => data
);

export const { setEmail, setPassword, setPhone } = LoginSlice.actions;

export default LoginSlice.reducer;