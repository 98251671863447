import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import PaymentLogModel from '../models/PaymentLogModel';

interface PaymentLogsState {
    paymentLogs: PaymentLogModel[],
    cursor: string,
    isDataLoaded: boolean
}

const initialState: PaymentLogsState = {
    paymentLogs: [],
    cursor: "",
    isDataLoaded: false
}

export const PaymentLogSlice = createSlice({
    name: "PaymentLogs",
    initialState,
    reducers: {
        setPaymentLogs: (state, action: PayloadAction<PaymentLogModel[]>) => {
            state.paymentLogs = [...action.payload];
        },
        setCursor: (state, action: PayloadAction<string>) => {
            state.cursor = action.payload;
        },
        setIsDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.isDataLoaded = action.payload;
        },
    }
});

export const paymentLogsSelector = createSelector(
    (state: RootState) => state.paymentLogs,
    (data) => data
);

export const {
    setPaymentLogs,
    setCursor,
    setIsDataLoaded
} = PaymentLogSlice.actions;

export default PaymentLogSlice.reducer;