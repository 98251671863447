import { Badge, List, ListItem } from '@material-ui/core';
import React, { useState } from 'react';
import {
    Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import NotificationApi from '../Notification/api/NotificationApi';
import BaseResponse from '../../app/BaseResponse';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationSelector, setNotificationCount } from '../Notification/reducers/NotificationReducer';
import { withStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
    toolBar: {
        display: "flex",
        "justify-content": "flex-end"
    },
    menuItem: {
        display: "block !important",
        color: "black !important",
        padding: "0 40px !important",
        height: "48px",
        "font-size": "14px",
        "line-height": "46px"
    },
    navMenu: {
        display: "flex",
        "margin-right": "auto"
    },
    navMenuRight: {
        display: "flex",
        "margin-left": "auto"
    },
    menuItemSelected: {
        background: "rgba(0, 0, 0, .12) !important"
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: "-16px",
        top: "16px",
        border: "2px solid ${theme.palette.background.paper}",
        padding: "0 4px",
    },
}))(Badge);


const LeftNav: React.FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notification = useSelector(NotificationSelector);
    const [currentPath, setCurrentPath] = useState<string>("");

    useEffect(() => {
        NotificationApi.CountUnread()
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    dispatch(setNotificationCount(response.data));
                }
            })
    }, []);

    const getMenuClass = (path: string) => {
        return classes.menuItem + (window.location.pathname == path || currentPath == path ? (" " + classes.menuItemSelected) : "")
    };

    return (
        <>
            <List>
                <Link to="/"
                    onClick={() => {
                        setCurrentPath("/");
                    }}
                >
                    <ListItem button
                        className={getMenuClass("/")}
                    >{t("Dashboard")}</ListItem>
                </Link>
                <Link to="/payment"
                    onClick={() => {
                        setCurrentPath("/payment");
                    }}>
                    <ListItem button
                        className={getMenuClass("/payment")}>{t("Payment")}</ListItem>
                </Link>
                <Link to="/notification"
                    onClick={() => {
                        setCurrentPath("/notification");
                    }}>
                    <ListItem button
                        className={getMenuClass("/notification")}>
                        {
                            notification.count !== "0" &&
                            <StyledBadge color="primary" badgeContent={notification.count}>
                                {t("Notification")}
                            </StyledBadge>
                        }
                        {
                            notification.count === "0" &&
                            <>{t("Notification")}</>
                        }
                    </ListItem>
                </Link>
                <Link to="/store"
                    onClick={() => {
                        setCurrentPath("/store");
                    }}>
                    <ListItem button
                        className={getMenuClass("/store")}>{t("Store")}</ListItem>
                </Link>
                <Link to="/mypackage"
                    onClick={() => {
                        setCurrentPath("/mypackage");
                    }}>
                    <ListItem button
                        className={getMenuClass("/mypackage")}>{t("My Packages")}</ListItem>
                </Link>
                <Link to="/myaccount"
                    onClick={() => {
                        setCurrentPath("/myaccount");
                    }}>
                    <ListItem button
                        className={getMenuClass("/myaccount")}>{t("My Account")}</ListItem>
                </Link>
                <Link to="/about" onClick={() => {
                    setCurrentPath("/about");
                }}
                >
                    <ListItem button
                        className={getMenuClass("/about")}>{t("About")}</ListItem>
                </Link>
            </List>
        </>
    )
};

export default LeftNav;