import globalConfig from '../config.json'
import axios from 'axios';

axios.defaults.baseURL = globalConfig.apiEndpoint;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    if (request.headers.ignoreCredentials) {
        delete request.headers.ignoreCredentials;
        request.withCredentials = false;
    } else {
        request.withCredentials = true;
    }

    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});