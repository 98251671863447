import { Drawer } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";
import Dashboard from '../Dashboard/Dashboard';
import LeftNav from '../LeftNav/LeftNav';
import Payment from '../Payment/Payment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Logout from '../Logout/Logout';
import Notification from '../Notification/Notification';
import Store from '../Store/Store';
import LoadingView from '../LoadingView/LoadingView';
import MyPackages from '../MyPackages/MyPackages';
import About from '../Dashboard/About';
import MyAccount from '../MyAccount/MyAccount';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {

    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        width: '220px',
        marginTop: "60px"
    },
    fixedHeight: {
        height: 240,
    },
    toolBar: {
        display: "flex",
        "justify-content": "flex-end"
    },
    menuItem: {
        display: "block",
        padding: "20px 25px",
        color: "black"
    },
    navMenu: {
        display: "flex",
        "margin-right": "auto"
    },
    navMenuRight: {
        display: "flex",
        "margin-left": "auto"
    },
    contentBackground: {
        content: " ",
        display: "block",
        position: "fixed",
        left: 0,
        top: 60,
        width: "100%",
        height: "calc(100% - 60px)",
        opacity: ".7",
        "background-image": "url(https://assets.resys.org/img/wavingbg.png)",
        "background-repeat": "no-repeat",
        "background-position": "50% 0",
        "background-size": "cover",
        "z-index": 1,
        "backgroundColor": "#fafafa"
    },
}));

const AppLayout: React.FunctionComponent = () => {
    const classes = useStyles();

    useEffect(() => {
        const script = document.createElement("script");

        script.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
            "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
            "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
            "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
            "})(window,document,'script','dataLayer','GTM-NKZK33G');";

        document.head.appendChild(script);

        const noScript = document.createElement("noscript");

        const iframe = document.createElement("iframe");
        iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-NKZK33G";
        iframe.width = "0";
        iframe.height = "0";
        iframe.style.cssText = "display:none;visibility:hidden";

        noScript.appendChild(iframe);
        document.body.prepend(noScript);
    }, [])

    return (
        <>
            <div className={classes.root}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.paper),
                    }}
                >
                    <LeftNav></LeftNav>
                </Drawer>

                <div className={classes.contentBackground}>
                </div>

                <main id="content">
                    <Switch>
                        <Route path="/mypackage">
                            <MyPackages></MyPackages>
                        </Route>
                        <Route path="/store">
                            <Store></Store>
                        </Route>
                        <Route path="/notification">
                            <Notification></Notification>
                        </Route>
                        <Route path="/logout">
                            <Logout></Logout>
                        </Route>
                        <Route path="/payment">
                            <Payment></Payment>
                        </Route>
                        <Route path="/about">
                            <About></About>
                        </Route>
                        <Route path="/myaccount">
                            <MyAccount></MyAccount>
                        </Route>
                        <Route path="/">
                            <Dashboard></Dashboard>
                        </Route>
                    </Switch>
                </main>
                <LoadingView></LoadingView>
            </div>
        </>
    )
};

export default AppLayout;