import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingViewSelector } from './reducers/LoadingViewReducer';
import './Loading.scss';

const LoadingView: React.FunctionComponent = () => {
    const loadingViewSelector = useSelector(LoadingViewSelector);

    return (
        <>
            {
                loadingViewSelector.showLoading &&
                <div className="spinner-wrapper">
                    <div className="spinner"></div>
                </div>
            }
        </>
    )
};

export default LoadingView;