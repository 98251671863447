import _ from "lodash";
import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";
import CouponModel from "../models/CouponModel";
import PackageModel from "../models/PackageModel";
import RequestModel from "../models/RequestModel";
import config from "../../../config.json";
import CheckOutOptionModel from "../models/CheckOutOptionModel";

const axiosConfig = {
    headers: {
        ignoreCredentials: true
    }
};

const getProducts = async <T>(url: string) => {
    return Api.get<unknown, T>(url, null, axiosConfig);
};

export const StoreUrl = {
    Request: "https://s.resys.org/prd_request.json",
    Coupon: "https://s.resys.org/prd_coupon.json",
    Package: "https://s.resys.org/prd_package.json",
}

const StoreApi = {
    GetRequests() {
        return getProducts<RequestModel[]>(StoreUrl.Request);
    },
    GetCoupons() {
        return getProducts<CouponModel[]>(StoreUrl.Coupon);
    },
    GetPackages() {
        return getProducts<PackageModel[]>(StoreUrl.Package);
    },
    Check(param: CheckParam) {
        return Api.post<CheckParam, BaseResponse<CheckOutOptionModel[]>>("/order/check", param);
    },
    CheckOut(param: CheckOutParam) {
        return Api.post<CheckOutParam, BaseResponse<string>>("/order/checkout", param);
    },
    GetBuyableProducts(param: GetBuyableProductsParam) {
        return Api.get<GetBuyableProductsParam, BaseResponse<string[]>>("/order/getbuyableproducts", param);
    }
};

export interface CheckParam {
    //service id
    sid: string,

    //quantity
    quantity: number,

    //data
    data: string
}

export interface CheckOutParam {
    //option id
    OptId: string,
}

export interface GetBuyableProductsParam {
    ProductTypes: string, //list of product types to get
}
export default StoreApi;