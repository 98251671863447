import React, { useEffect } from 'react';
import './App.css';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import Login, { LoginTypeEnum } from './components/Login/Login';
import AppLayout from './components/AppLayout/AppLayout';
import { setLang, setToken, userProfileSelector } from './components/Login/reducers/UserProfileReducer';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Utility from './app/utility';
import i18n from './app/i18n';
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.min.css';
import { useState } from 'react';

const App: React.FunctionComponent = () => {
  const userProfile = useSelector(userProfileSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    processFirstAuthentication();
  }, []);

  useEffect(() => {
    Utility.getLanguage()
      .then((lang: string) => {
        dispatch(setLang(lang));
        i18n.changeLanguage(lang);
        setFirstLoad(true);
      });
  }, [userProfile.Token]);

  const processFirstAuthentication = () => {
    const token = Cookies.get("token");
    if (token && token.length > 0) {
      dispatch(setToken(token));
    } else {
      const backTo = Utility.getQueryStringByName("backto");
      let loginUrl = "/login";
      if (backTo) {
        loginUrl += "?backto=" + backTo;
      }

      history.push(loginUrl);
    }
  };

  return (
    <>
      {firstLoad && (!userProfile.Token || userProfile.Token.length === 0) &&
        <Switch>
          <Route path="/login">
            <Login
              type={LoginTypeEnum.Login}
            ></Login>
          </Route>
          <Route path="/register">
            <Login
              type={LoginTypeEnum.Register}
            ></Login>
          </Route>
        </Switch>
      }

      {firstLoad && (userProfile.Token && userProfile.Token.length > 0) &&
        <AppLayout>
        </AppLayout>
      }
    </>
  );
}

export default App;
