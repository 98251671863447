import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Datatable, { DatatableText, PagingTypeEnum } from '../Datatable/Datatable';
import DatatableColumnModel from '../Datatable/DatatableColumnModel';
import { useEffect } from 'react';
import BaseResponse from '../../app/BaseResponse';
import Utility from '../../app/utility';
import NotificationModel from './models/NotificationModel';
import { NotificationSelector, setCursor, setIsDataLoaded, setNotificationCount, setNotifications } from './reducers/NotificationReducer';
import NotificationApi, { GetNotificationParam, MarkReadParam } from './api/NotificationApi';
import { Button, Tooltip } from '@material-ui/core';

const Notification: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const notification = useSelector(NotificationSelector);
    const dispatch = useDispatch();

    const paymentLogsColumns = [
        {
            Field: "create_time",
            Title: t("Date"),
            Render: (rowData: NotificationModel) => (
                <>
                    {rowData.status === "Unread" ?
                        <b>{Utility.DateFormat.getDateDDMMYYYY(rowData.create_time)}</b> :
                        Utility.DateFormat.getDateDDMMYYYY(rowData.create_time)
                    }
                </>
            )
        } as DatatableColumnModel<NotificationModel>,
        {
            Field: "create_time",
            Title: t("Time"),
            Render: (rowData: NotificationModel) => (
                <>
                    {
                        rowData.status === "Unread" ?
                            <b>{Utility.DateFormat.getTime(rowData.create_time)}</b> :
                            Utility.DateFormat.getTime(rowData.create_time)
                    }
                </>
            )
        } as DatatableColumnModel<NotificationModel>,
        {
            Field: "title",
            Title: t("Title"),
            Render: (rowData: NotificationModel) => (
                <>
                    {
                        rowData.status === "Unread" ?
                            <b>{rowData.title}</b> :
                            rowData.title
                    }
                </>
            )
        } as DatatableColumnModel<NotificationModel>,
        {
            Field: "msg",
            Title: t("Details"),
            Render: (rowData: NotificationModel) => (
                rowData.status === "Unread" ?
                    <b>
                        <div dangerouslySetInnerHTML={{ __html: rowData.msg }}>
                        </div>
                    </b>
                    :
                    <div dangerouslySetInnerHTML={{ __html: rowData.msg }}>
                    </div>
            )
        } as DatatableColumnModel<NotificationModel>,
        {
            Field: "status",
            Title: t("Action"),
            Render: (rowData: NotificationModel) => (
                <>
                    {rowData.status === "Unread" &&
                        <Tooltip title={<>{t("Mark as read")}</>}>
                            <Button
                                onClick={() => {
                                    markRead(rowData.nid);
                                }}
                            >
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </Button>
                        </Tooltip>
                    }
                </>
            )
        } as DatatableColumnModel<NotificationModel>,
    ];

    useEffect(() => {
        document.title = t("Notification")

        //prevent load data many times when navigate to other route then back, because data is store global
        if (notification.notifications.length === 0) {
            loadNotifications();
        }
    }, []);

    const loadNotifications = () => {
        let param = {
            pc: notification.cursor
        } as GetNotificationParam;

        NotificationApi.GetNotifications(param)
            .then((response: BaseResponse<NotificationModel[]>) => {
                if (response.is_success) {
                    let data = [...notification.notifications, ...response.data];

                    dispatch(setNotifications(data));
                    dispatch(setCursor(response.cursor));
                    
                    dispatch(setIsDataLoaded(true));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
    };

    const updateNotificationCount = () => {
        NotificationApi.CountUnread()
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    dispatch(setNotificationCount(response.data));
                }
            })
    }

    const markRead = (nid: string) => {
        const param = {
            nid: nid
        } as MarkReadParam;

        NotificationApi.MarkRead(param)
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    let data = [...Utility.CloneObject(notification.notifications)];

                    data.forEach(notification => {
                        if (notification.nid === nid || nid.length === 0)
                            notification.status = "Read";
                    });

                    dispatch(setNotifications(data));

                    updateNotificationCount();
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
    }

    return (
        <>
            <div>
                <Button
                    onClick={() => {
                        markRead("");
                    }}
                    color="primary"
                    variant="contained"
                >
                    <i className="fa fa-envelope-open-o" aria-hidden="true"></i>&nbsp;&nbsp;
                    {t("Mark All As Read")}
                </Button>
            </div>
            <br />
            <Datatable<NotificationModel>
                useDefaultSearchBar={false}
                pagingType={PagingTypeEnum.ShowMore}
                data={notification.notifications}
                useItemPerPage={false}
                columns={paymentLogsColumns}
                onShowMoreClick={() => {
                    dispatch(setIsDataLoaded(false));
                    loadNotifications();
                }}
                customText={{
                    showMoreButtonText: t("Load More")
                } as DatatableText}
                isDataLoaded={notification.isDataLoaded}
                showMoreMode_IsLastPage={!notification.cursor}
            ></Datatable>
        </>
    )
};

export default Notification;