import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import NotificationModel from '../models/NotificationModel';

interface NotificationState {
    notifications: NotificationModel[],
    cursor: string,
    count: string,
    isDataLoaded: boolean
}

const initialState: NotificationState = {
    notifications: [],
    cursor: "",
    count: "0",
    isDataLoaded: false
}

export const NotificationSlice = createSlice({
    name: "Notification",
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<NotificationModel[]>) => {
            state.notifications = [...action.payload];
        },
        setCursor: (state, action: PayloadAction<string>) => {
            state.cursor = action.payload;
        },
        setNotificationCount: (state, action: PayloadAction<string>) => {
            state.count = action.payload;
        },
        setIsDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.isDataLoaded = action.payload;
        },
    }
});

export const NotificationSelector = createSelector(
    (state: RootState) => state.notifications,
    (data) => data
);

export const {
    setNotifications,
    setCursor,
    setNotificationCount,
    setIsDataLoaded
} = NotificationSlice.actions;

export default NotificationSlice.reducer;