import _ from "lodash";
import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";

const axiosConfig = {
    headers: {
        ignoreCredentials: true
    }
};

export interface StaticHtmlResponse {
    en: string,
    vi: string
}

const getHtml = async <T>(url: string) => {
    return Api.get<unknown, T>(url, null, axiosConfig);
};

export const StaticHtml = {
    Dashboard: "https://s.resys.org/displayhtml/account/dashboard.json",
    About: "https://s.resys.org/displayhtml/account/about.json",
}

const StaticHtmlApi = {
    GetDashboard() {
        return getHtml<StaticHtmlResponse>(StaticHtml.Dashboard);
    },
    GetAbout() {
        return getHtml<StaticHtmlResponse>(StaticHtml.About);
    },
};

export default StaticHtmlApi;