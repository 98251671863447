import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface MyAccountState {
    phone: string,
    oldPassword: string,
    password: string,
    confirmPassword: string,
    lang: string
}

const initialState: MyAccountState = {
    phone: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
    lang: ""
}

export const MyAccountSlice = createSlice({
    name: "UserProfile",
    initialState,
    reducers: {
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setOldPassword: (state, action: PayloadAction<string>) => {
            state.oldPassword = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setConfirmPassword: (state, action: PayloadAction<string>) => {
            state.confirmPassword = action.payload;
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.lang = action.payload;
        },
    }
});

export const myAccountSelector = createSelector(
    (state: RootState) => state.myAccount,
    (data) => data
);

export const { setPhone, setOldPassword, setPassword, setConfirmPassword, setLanguage } = MyAccountSlice.actions;

export default MyAccountSlice.reducer;