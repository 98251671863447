import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
import './css/store.scss'
import StoreApi, { GetBuyableProductsParam } from './api/StoreApi';
import { setBuyableProducts, setCoupons, setPackages, setRequests } from './reducers/StoreReducer';
import CouponModel from './models/CouponModel';
import PackageModel from './models/PackageModel';
import ProductList from './ProductList';
import { ProductType } from './models/ProductModel';
import ProductDetailPopup from './ProductDetailPopup';
import ProductDetail from './ProductDetail';
import CheckoutPopup from './CheckoutPopup';
import BaseResponse from '../../app/BaseResponse';

const Store: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(ProductType.Coupon.toString());
    // const [value, setValue] = useState(ProductType.Request.toString());

    useEffect(() => {
        document.title = t("Store");

        getProducts();

        getBuyableProducts();

    }, []);

    const getProducts = () => {
        StoreApi.GetCoupons().then((data: CouponModel[]) => {
            dispatch(setCoupons(data));
        })

        // StoreApi.GetRequests().then((data: RequestModel[]) => {
        //     dispatch(setRequests(data));
        // })

        StoreApi.GetPackages().then((data: PackageModel[]) => {
            dispatch(setPackages(data));
        })
    };

    const getBuyableProducts = () => {
        let param = {
            ProductTypes: ["coupon", "package"].join(",")
        } as GetBuyableProductsParam;

        StoreApi.GetBuyableProducts(param).then((response: BaseResponse<string[]>) => {
            if (response.is_success) {
                dispatch(setBuyableProducts(response.data));
            }
        })
    };

    return (
        <>
            <ProductDetailPopup></ProductDetailPopup>
            <CheckoutPopup></CheckoutPopup>
            <TabContext value={selectedTab}>
                <TabList
                    className="store-tab-list"
                    indicatorColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        setSelectedTab(newValue);
                    }}>
                    {/* <Tab className="store-tab" label={t("Request")} value={ProductType.Request.toString()} /> */}
                    <Tab className="store-tab" label={t("Coupon")} value={ProductType.Coupon.toString()} />
                    <Tab className="store-tab" label={t("Packages")} value={ProductType.Package.toString()} />
                </TabList>
                <div className="store-tab-panel">
                    {/* <TabPanel value={ProductType.Request.toString()}>
                        <ProductList
                            productType={ProductType.Request}
                        >
                        </ProductList>
                    </TabPanel> */}
                    <TabPanel value={ProductType.Coupon.toString()} className="inner-panel">
                        <ProductList
                            productType={ProductType.Coupon}
                        >
                        </ProductList>
                    </TabPanel>
                    <TabPanel value={ProductType.Package.toString()} className="inner-panel">
                        <ProductList
                            productType={ProductType.Package}
                        >
                        </ProductList>
                    </TabPanel>
                </div>
            </TabContext>
        </>
    )
};

export default Store;