import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";
import config from "../../../config.json";
import NotificationModel from "../models/NotificationModel";

export interface MarkReadParam {
    nid: string
}

export interface GetNotificationParam {
    pc?: string
}

const axiosConfig = {
    headers: {
        'Content-Type': 'text/plain',
    }
};

const NotificationApi = {
    GetNotifications(param: GetNotificationParam) {
        return Api.get<GetNotificationParam, BaseResponse<NotificationModel[]>>(config.userEndpoint + "/notification/list", param);
    },
    MarkRead(param: MarkReadParam) {
        return Api.post<MarkReadParam, BaseResponse<string>>(config.userEndpoint + "/notification/read", param, axiosConfig);
    },
    CountUnread() {
        return Api.get<unknown, BaseResponse<string>>(config.userEndpoint + "/notification/count", null);
    },
};
export default NotificationApi;