import { Table, TableBody, TableHead, TableRow, TableCell, makeStyles, InputAdornment, TextField, MenuItem, Select } from "@material-ui/core";
import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import DatatableColumnModel, { DatatableColumnAlign } from "./DatatableColumnModel";
import SearchIcon from '@material-ui/icons/Search';
import { useState } from "react";
import { Button } from "@material-ui/core";
import { useEffect } from "react";

interface DatatableProps<T> {
    columns: DatatableColumnModel<T>[],
    data: T[],
    pagingType: PagingTypeEnum,
    useDefaultSearchBar?: boolean,
    useItemPerPage?: boolean,
    onPageChanged?: any,
    onPageSizeChanged?: any,
    onShowMoreClick?: any,
    customText?: DatatableText,
    showMoreMode_IsLastPage?: boolean,
    isDataLoaded: boolean,
}

interface DatatableState {
    Data: object[],
    PageSize: number,
}

export enum PagingTypeEnum {
    Standard,
    ShowMore,
    NextBackFireStore,
}

export interface DatatableText {
    showMoreButtonText: string,
    showMoreLoadingText: string
}

const useStyles = makeStyles({
    tableWrapper: {
        "box-shadow": "0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)"
    },
    searchContainer: {
        "display": "flex",
        "justify-content": "flex-end",
        "padding-bottom": "20px"
    },
    pagingArea: {
        "display": "flex",
        "justify-content": "flex-end",
        backgroundColor:"#FFFFFF"
    },
    "datatable-row": {
        "&:hover": {
            "background-color": "#f0f0f0"
        }
    },
    "datatable-show-more-area": {
        "display": "flex",
        "justify-content": "center",
        "padding": "20px",
        "width": "100%"
    },
    "datatable": {
        backgroundColor: "#FFFFFF"
    },
    "datatable-head": {
        backgroundColor: "#f1f5fa"
    },
    "datatable-cell": {
        borderLeft: "1px solid rgba(0,0,0,.12)"
    }
});

const initDatatableText = {
    showMoreButtonText: "Show more",
    showMoreLoadingText: "Loading"
} as DatatableText;

const Datatable = <T extends object>(props: DatatableProps<T> & { children?: ReactNode }) => {
    const classes = useStyles();

    const [tableState, setTableState] = useState({
        data: [],
        PageSize: 10
    } as unknown as DatatableState);

    const [textState, setTextState] = useState({
    } as DatatableText);

    useEffect(() => {
        if (props.customText) {
            mapCustomText(props.customText);
        } else {
            setTextState(initDatatableText)
        }
    }, []);

    const mapCustomText = (customTextProp: DatatableText) => {
        let text = {} as DatatableText;

        let key: keyof DatatableText;
        for (key in initDatatableText) {
            if (!customTextProp.hasOwnProperty(key) || customTextProp[key] === null || customTextProp[key].length === 0) {
                text[key] = initDatatableText[key];
            } else {
                text[key] = customTextProp[key];
            }
        }

        setTextState(text);
    };

    const renderCell = (row: T, column: DatatableColumnModel<T>) => {
        let key: keyof T;

        for (key in row) {
            if (key == column.Field) {
                return row[key];
            }
        }

        return "";
    };

    return (
        <>
            {props.useDefaultSearchBar &&
                <div className={classes.searchContainer}>
                    <TextField
                        label="Search"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <SearchIcon></SearchIcon>
                            </InputAdornment>,
                        }}
                    />
                </div>
            }
            <div className={classes.tableWrapper}>
                <Table className={classes.datatable}>
                    <TableHead>
                        <TableRow className={classes["datatable-head"]}>
                            {
                                props.columns.map((column: DatatableColumnModel<T>) => (
                                    <TableCell
                                        className={classes["datatable-cell"]}
                                        width={column.Width}
                                        align={
                                            column.Align == DatatableColumnAlign.Left ? "left" :
                                                column.Align == DatatableColumnAlign.Center ? "center" :
                                                    column.Align == DatatableColumnAlign.Right ? "right" : "inherit"
                                        }>
                                        {column.Title}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data.map((row: T) => (
                                <TableRow className={classes["datatable-row"]}>
                                    {
                                        props.columns.map((column: DatatableColumnModel<T>) => (
                                            <TableCell 
                                                className={classes["datatable-cell"]}
                                                align={
                                                    column.Align == DatatableColumnAlign.Left ? "left" :
                                                        column.Align == DatatableColumnAlign.Center ? "center" :
                                                            column.Align == DatatableColumnAlign.Right ? "right" : "inherit"
                                                }>
                                                {column.Render && column.Render(row)}
                                                {!column.Render && renderCell(row, column)}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <div className={classes.pagingArea}>
                    {
                        props.useItemPerPage &&
                        <div>
                            <span>Items per pages:</span>
                            <Select
                                value={tableState.PageSize}
                                onChange={(e) => {
                                    setTableState(prev => ({
                                        ...prev,
                                        PageSize: e.target.value as number
                                    }))

                                    if (props.onPageSizeChanged) {
                                        props.onPageSizeChanged(tableState.PageSize);
                                    }
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </div>
                    }
                    {
                        props.pagingType === PagingTypeEnum.ShowMore && !props.showMoreMode_IsLastPage &&
                        <div className={classes["datatable-show-more-area"]}>
                            <div>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (props.onShowMoreClick) {
                                            props.onShowMoreClick();
                                        }
                                    }}
                                    disabled={!props.isDataLoaded}
                                >
                                    {props.isDataLoaded && textState.showMoreButtonText}
                                    {!props.isDataLoaded && textState.showMoreLoadingText}
                                </Button>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
};

export default Datatable;