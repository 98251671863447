import { MyAccountState } from './../reducers/MyAccountReducer';
import _ from "lodash";
import Api from "../../../app/apiRequest";
import BaseResponse from "../../../app/BaseResponse";

export interface UpdatePasswordParam {
    oldPassword: string,
    password: string
}

export interface UpdatePhoneParam {
    phone: string,
}

export interface UpdateLanguageParam {
    lang: string,
}

const MyAccountApi = {
    UpdatePassword(param: UpdatePasswordParam) {
        return Api.post<UpdatePasswordParam, BaseResponse<string>>("/user/me/update_password", param);
    },
    UpdatePhone(param: UpdatePhoneParam) {
        return Api.post<UpdatePhoneParam, BaseResponse<string>>("/user/me/update_phone", param);
    },
    UpdateLanguage(param: UpdateLanguageParam) {
        return Api.post<UpdateLanguageParam, BaseResponse<string>>("/user/me/update_language", param);
    },
    GetUserInfo() {
        return Api.get<null, BaseResponse<MyAccountState>>("/user/me", null);
    },
};

export default MyAccountApi;