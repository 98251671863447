import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"

interface LoadingViewState {
    showLoading: boolean
}


const initialState: LoadingViewState = {
    showLoading: false
}

export const LoadingViewSlice = createSlice({
    name: "LoadingView",
    initialState,
    reducers: {
        showLoading: (state, action: PayloadAction<boolean>) => {
            state.showLoading = action.payload;
        },
    }
});

export const LoadingViewSelector = createSelector(
    (state: RootState) => state.loadingView,
    (data) => data
);

export const {
    showLoading,
} = LoadingViewSlice.actions;

export default LoadingViewSlice.reducer;