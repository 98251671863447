export default interface DatatableColumnModel<T> {
    Field: string,
    Title: string,
    Align: DatatableColumnAlign,
    Width: string,
    Render: (rowData: T) => JSX.Element,
}

export enum DatatableColumnAlign {
    Left,
    Center,
    Right
}