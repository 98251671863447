import Api from "./apiRequest";
import BaseResponse from "./BaseResponse";

export interface ResourceModel {
    key: string,
    html: string
}

export interface GetResourceParam {
    Lang: string,
    Key: string
}

export interface GetResourcesParam {
    Lang: string,
    Keys: string
}

const ResourceApi = {
    GetResource(param: GetResourceParam) {
        return Api.get<GetResourceParam, BaseResponse<ResourceModel>>("/resource/getresource", param);
    },
    GetResources(param: GetResourcesParam) {
        return Api.get<GetResourcesParam, BaseResponse<ResourceModel[]>>("/resource/getresources", param);
    },
};
export default ResourceApi;