import React, { useEffect } from 'react';
import './Login.scss'
import './utils.scss'
import { setToken, userProfileSelector } from './reducers/UserProfileReducer';
import { useHistory } from 'react-router-dom';
import Utility from '../../app/utility';
import { useTranslation } from 'react-i18next';
import LoginApi, { LoginParam, LoginResponse, RegisterParam } from './api/LoginApi';
import BaseResponse from '../../app/BaseResponse';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loginSelector, setEmail, setPassword, setPhone } from './reducers/LoginReducer';

const Tilt = require('react-tilt').default;

interface LoginType {
    type: LoginTypeEnum
}

export enum LoginTypeEnum {
    Login,
    Register
}

const Login: React.FunctionComponent<LoginType> = ({ type }) => {
    const dispatch = useDispatch();
    const backTo = Utility.getQueryStringByName("backto")
    const history = useHistory();
    const { t } = useTranslation();
    const userProfile = useSelector(userProfileSelector);
    const loginData = useSelector(loginSelector);

    useEffect(() => {
        if (type === LoginTypeEnum.Login) {
            document.title = t("Login")
        } else {
            document.title = t("Register")
        }

        //reset data
        dispatch(setEmail(""));
        dispatch(setPassword(""));
        dispatch(setPhone(""));
    }, []);

    const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" || e.key === "NumpadEnter") {
            submitForm();
        }
    }

    const submitForm = () => {
        try {
            validateForm();

            if (type === LoginTypeEnum.Login) {
                login();
            } else {
                register();
            }
        } catch (error) {
            Utility.Alert.Error(error);
        }
    };

    const login = () => {
        const loginParam = {
            email: loginData.email,
            password: loginData.password
        } as LoginParam;

        LoginApi.Login(loginParam)
            .then((response: BaseResponse<LoginResponse>) => {
                if (response.is_success) {
                    if (backTo && backTo.length > 0) {
                        window.location.href = backTo;
                    } else {
                        Utility.Alert.Success("Login Successfully");

                        setTimeout(() => {
                            dispatch(setToken(response.data.token));

                            history.push("/");
                        }, 1000);
                    }
                } else {
                    Utility.Alert.Error(response.exception);
                }
            });
    };

    const register = () => {
        const registerParam = {
            email: loginData.email,
            phone: loginData.phone,
            lang: userProfile.Lang
        } as RegisterParam;

        LoginApi.Register(registerParam)
            .then((response: BaseResponse<LoginResponse>) => {
                if (response.is_success) {
                    Utility.Alert.Success("Register Successfully. Please check your email.");

                    dispatch(setToken(response.data.token));

                    setTimeout(() => {
                        history.push("/login");
                    }, 1000);

                } else {
                    Utility.Alert.Error(response.exception);
                }
            });
    };

    const validateForm = () => {
        Utility.validate.Gmail(loginData.email);
        if (type === LoginTypeEnum.Login) {
            Utility.validate.Notblank(loginData.password);
        } else {
            Utility.validate.Phone(loginData.phone);
        }
    };

    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="login100-pic js-tilt">
                            <Tilt>
                                <img src="/img/resys_incomp.png" alt="logo" />
                            </Tilt>
                        </div>
                        <div className="login100-form">
                            <span className="login100-form-title">
                                {type === LoginTypeEnum.Login ? t("Login") : t("Register")}
                            </span>
                            <div className="wrap-input100">
                                <TextField
                                    label={t("Email")}
                                    onChange={(e) => {
                                        dispatch(setEmail(e.target.value.trim()))
                                    }}
                                    onKeyPress={handleEnterKey}
                                    className="input100"
                                >
                                </TextField>
                            </div>
                            {
                                type === LoginTypeEnum.Login &&
                                <div className="wrap-input100">
                                    <TextField
                                        label={t("Password")}
                                        type="password"
                                        onChange={(e) => {
                                            dispatch(setPassword(e.target.value.trim()))
                                        }}
                                        onKeyPress={handleEnterKey}
                                        className="input100"
                                    >
                                    </TextField>
                                </div>
                            }
                            {
                                type === LoginTypeEnum.Register &&
                                <div className="wrap-input100">
                                    <TextField
                                        label={t("Phone Number")}
                                        onChange={(e) => {
                                            dispatch(setPhone(e.target.value.trim()))
                                        }}
                                        onKeyPress={handleEnterKey}
                                        className="input100"
                                    >
                                    </TextField>
                                </div>
                            }
                            <div className="container-login100-form-btn">
                                <button
                                    id="submit_login"
                                    className="login100-form-btn"
                                    onClick={submitForm}
                                >
                                    {type === LoginTypeEnum.Login ? t("Login") : t("Register")}
                                </button>
                            </div>
                            <div className="text-center p-t-136">
                                <p>
                                    {type === LoginTypeEnum.Login ? t("No Account") : t("Have Account")} ?
                                    &nbsp;
                                    <a
                                        className="txt2"
                                        onClick={() => {
                                            if (type === LoginTypeEnum.Login) {
                                                history.push("/register")
                                            } else {
                                                history.push("/login")
                                            }
                                        }}
                                    >
                                        {type === LoginTypeEnum.Login ? t("Register Now") : t("Login Now")} ?
                                        <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Login;