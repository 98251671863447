import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import RequestModel from '../models/RequestModel';
import CouponModel from '../models/CouponModel';
import PackageModel from '../models/PackageModel';

interface ProductState {
    requests: RequestModel[],
    coupons: CouponModel[],
    packages: PackageModel[],
    isDataLoaded: boolean,
    buyableProducts: string[],
}

const initialState: ProductState = {
    requests: [],
    coupons: [],
    packages: [],
    isDataLoaded: false,
    buyableProducts: []
}

export const StoreSlice = createSlice({
    name: "Store",
    initialState,
    reducers: {
        setRequests: (state, action: PayloadAction<RequestModel[]>) => {
            state.requests = [...action.payload];
        },
        setCoupons: (state, action: PayloadAction<CouponModel[]>) => {
            state.coupons = [...action.payload];
        },
        setPackages: (state, action: PayloadAction<PackageModel[]>) => {
            state.packages = [...action.payload];
        },
        setIsDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.isDataLoaded = action.payload;
        },
        setBuyableProducts: (state, action: PayloadAction<string[]>) => {
            state.buyableProducts = action.payload;
        },
    }
});

export const ProductSelector = createSelector(
    (state: RootState) => state.store,
    (data) => data
);

export const {
    setRequests,
    setCoupons,
    setPackages,
    setIsDataLoaded,
    setBuyableProducts
} = StoreSlice.actions;

export default StoreSlice.reducer;