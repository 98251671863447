import { MyAccountSlice } from './../components/MyAccount/reducers/MyAccountReducer';
import { StaticHtmlSlice } from './../components/Dashboard/reducers/StaticHtmlReducer';
import { MySubscriptionsSlice } from '../components/MyPackages/reducers/MySubscriptionsReducer';
import { CheckOutPopupSlice } from './../components/Store/reducers/CheckOutPopupReducer';
import { LoadingViewSlice } from './../components/LoadingView/reducers/LoadingViewReducer';
import { ProductDetailSlice } from '../components/Store/reducers/ProductDetailReducer';
import { LoginSlice } from './../components/Login/reducers/LoginReducer';
import { NotificationSlice } from './../components/Notification/reducers/NotificationReducer';
import { PaymentLogSlice } from './../components/Payment/reducer/PaymentLogsReducer';
import { PaymentUISlice } from './../components/Payment/reducer/PaymentUIReducer';
import { UserProfileSlice } from '../components/Login/reducers/UserProfileReducer';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { StoreSlice } from '../components/Store/reducers/StoreReducer';

export const store = configureStore({
  reducer: {
    userProfile: UserProfileSlice.reducer,
    paymentUI: PaymentUISlice.reducer,
    paymentLogs: PaymentLogSlice.reducer,
    notifications: NotificationSlice.reducer,
    login: LoginSlice.reducer,
    store: StoreSlice.reducer,
    productDetail: ProductDetailSlice.reducer,
    loadingView: LoadingViewSlice.reducer,
    checkOutPopup: CheckOutPopupSlice.reducer,
    mySubscriptions: MySubscriptionsSlice.reducer,
    staticHtml: StaticHtmlSlice.reducer,
    myAccount: MyAccountSlice.reducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
