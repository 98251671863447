import { RootState } from '../../../app/store';
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import RequestModel from '../models/RequestModel';
import CouponModel from '../models/CouponModel';
import PackageModel from '../models/PackageModel';
import { ProductType } from '../models/ProductModel';
import CheckOutOptionModel from '../models/CheckOutOptionModel';

interface CheckOutPopupState {
    open: boolean,
    checkOutOptions: CheckOutOptionModel[],
}

const initialState: CheckOutPopupState = {
    open: false,
    checkOutOptions: []
}

export const CheckOutPopupSlice = createSlice({
    name: "ProductDetail",
    initialState,
    reducers: {
        setCheckoutOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setCheckOutOptions: (state, action: PayloadAction<CheckOutOptionModel[]>) => {
            state.checkOutOptions = action.payload;
        },
    }
});

export const CheckOutPopupSelector = createSelector(
    (state: RootState) => state.checkOutPopup,
    (data) => data
);

export const {
    setCheckoutOpen,
    setCheckOutOptions,
} = CheckOutPopupSlice.actions;

export default CheckOutPopupSlice.reducer;