import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Datatable, { DatatableText, PagingTypeEnum } from '../Datatable/Datatable';
import { paymentLogsSelector, setCursor, setIsDataLoaded, setPaymentLogs } from './reducer/PaymentLogsReducer';
import DatatableColumnModel, { DatatableColumnAlign } from '../Datatable/DatatableColumnModel';
import PaymentLogModel from './models/PaymentLogModel';
import { useEffect } from 'react';
import PaymentApi, { GetLogsParam } from './api/PaymentApi';
import BaseResponse from '../../app/BaseResponse';
import Utility from '../../app/utility';

const PaymentLogTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const paymentLogs = useSelector(paymentLogsSelector);
    const dispatch = useDispatch();

    const paymentLogsColumns = [
        {
            Field: "index",
            Title: "#",
            Align: DatatableColumnAlign.Center,
            Width: "100px"
        } as DatatableColumnModel<PaymentLogModel>,
        {
            Field: "create_time",
            Title: t("Date"),
            Align: DatatableColumnAlign.Center,
            Width: "200px",
            Render: (rowData: PaymentLogModel) => (
                <>
                    {Utility.DateFormat.getDateDDMMYYYY(rowData.create_time)}
                </>
            )
        } as DatatableColumnModel<PaymentLogModel>,
        {
            Field: "create_time",
            Title: t("Time"),
            Align: DatatableColumnAlign.Center,
            Width: "200px",
            Render: (rowData: PaymentLogModel) => (
                <>
                    {Utility.DateFormat.getTime(rowData.create_time)}
                </>
            )
        } as DatatableColumnModel<PaymentLogModel>,
        {
            Field: "type",
            Title: t("Type"),
            Align: DatatableColumnAlign.Center,
            Width: "200px",
            Render: (rowData: PaymentLogModel) => (
                <>
                    {rowData.type === "Credit" && <i className="fa fa-money"></i>}
                    {rowData.type === "Gift" && <span className="payment-diamond-reward-icon"><i className="fa fa-diamond"></i></span>}
                </>
            )
        } as DatatableColumnModel<PaymentLogModel>,
        {
            Field: "amount",
            Title: t("Amount"),
            Align: DatatableColumnAlign.Left,
            Width: "200px",
            Render: (rowData: PaymentLogModel) => (
                <>
                    {rowData.type === "Credit" &&
                        (rowData.transaction_type === "in" ?
                            <b>{"+ " + parseInt(rowData.amount).toLocaleString()}</b> :
                            "- " + parseInt(rowData.amount).toLocaleString())}
                    {rowData.type === "Gift" &&
                        (<span className="payment-diamond-reward-icon">
                            {(rowData.transaction_type === "in" ?
                                <b>{"+ " + parseInt(rowData.amount).toLocaleString()}</b> :
                                "- " + parseInt(rowData.amount).toLocaleString())}</span>)}
                </>
            )
        } as DatatableColumnModel<PaymentLogModel>,
        {
            Field: "note",
            Align: DatatableColumnAlign.Left,
            Title: t("Note"),
        } as DatatableColumnModel<PaymentLogModel>,
    ];

    useEffect(() => {
        //prevent load data many times when navigate to other route then back, because data is store global
        if (paymentLogs.paymentLogs.length === 0) {
            loadPaymentLogs();
        }
    }, []);

    const loadPaymentLogs = () => {
        let param = {
            pc: paymentLogs.cursor
        } as GetLogsParam;

        PaymentApi.GetLogs(param)
            .then((response: BaseResponse<PaymentLogModel[]>) => {
                if (response.is_success) {
                    let data = [...Utility.CloneObject(paymentLogs.paymentLogs), ...response.data];
                    mapTableIndex(data);
                    
                    dispatch(setPaymentLogs(data));
                    dispatch(setCursor(response.cursor));
                    dispatch(setIsDataLoaded(true));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
    };

    const mapTableIndex = (paymentLogs: PaymentLogModel[]) => {
        let index = 1;
        paymentLogs.forEach(paymentLog => {
            paymentLog.index = index;
            index++;
        });
    };

    return (
        <Datatable<PaymentLogModel>
            useDefaultSearchBar={false}
            pagingType={PagingTypeEnum.ShowMore}
            data={paymentLogs.paymentLogs}
            useItemPerPage={false}
            columns={paymentLogsColumns}
            onShowMoreClick={() => {
                dispatch(setIsDataLoaded(false));
                loadPaymentLogs();
            }}
            customText={{
                showMoreButtonText: t("Load More")
            } as DatatableText}
            isDataLoaded={paymentLogs.isDataLoaded}
            showMoreMode_IsLastPage={!paymentLogs.cursor}
        ></Datatable>
    )
};

export default PaymentLogTable;