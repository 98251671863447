import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { Button, Grid, TextField } from '@material-ui/core';
import './MyAccount.scss';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import MyAccountApi, { UpdateLanguageParam, UpdatePasswordParam, UpdatePhoneParam } from './api/MyAccountApi';
import BaseResponse from '../../app/BaseResponse';
import { myAccountSelector, MyAccountState, setConfirmPassword, setLanguage, setOldPassword, setPassword, setPhone } from './reducers/MyAccountReducer';
import Utility from '../../app/utility';
import { showLoading } from '../LoadingView/reducers/LoadingViewReducer';

interface LanguageModel {
    label: string,
    value: string
}

const MyAccount: React.FunctionComponent = () => {
    const languages = [{
        label: "English",
        value: "en"
    } as LanguageModel,
    {
        label: "Tiếng Việt",
        value: "vi"
    } as LanguageModel]

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userProfile = useSelector(userProfileSelector);
    const myAccountData = useSelector(myAccountSelector);


    useEffect(() => {
        document.title = t("My Account");

        dispatch(setLanguage(userProfile.Lang));

        dispatch(showLoading(true));
        MyAccountApi.GetUserInfo()
            .then((response: BaseResponse<MyAccountState>) => {
                if (response.is_success) {
                    dispatch(setPhone(response.data.phone));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
            .catch((error) => {
                Utility.Alert.Error(error);
            })
            .finally(() => {
                dispatch(showLoading(false));
            })
    }, []);

    const getLanguage = (lang: string): LanguageModel | undefined => {
        let value = _.find(languages, (language) => {
            return language.value === lang;
        });

        if (!value) {
            value = languages[0];
        }

        console.log(value);
        return value;
    }

    const savePhone = () => {
        if (myAccountData.phone.length < 8 || !/^[0-9]*$/.test(myAccountData.phone)) {
            Utility.Alert.Error("Invalid phone number");
            return;
        }

        dispatch(showLoading(true));

        MyAccountApi.UpdatePhone({
            phone: myAccountData.phone
        } as UpdatePhoneParam)
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    Utility.Alert.Success(t("Save successful!"));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
            .catch((error) => {
                Utility.Alert.Error(error);
            }).finally(() => {
                dispatch(showLoading(false));
            })
    }

    const updatePassword = () => {
        if (!myAccountData.oldPassword || myAccountData.oldPassword.length === 0) {
            Utility.Alert.Error(t("Old password is required"));
            return;
        }

        if (!myAccountData.password || myAccountData.password.length === 0) {
            Utility.Alert.Error(t("Password is required"));
            return;
        }

        if (!myAccountData.confirmPassword || myAccountData.confirmPassword.length === 0) {
            Utility.Alert.Error(t("Confirm password is required"));
            return;
        }

        if (myAccountData.password != myAccountData.confirmPassword) {
            Utility.Alert.Error(t("Password and confirm password are not match"));
            return;
        }

        dispatch(showLoading(true));

        MyAccountApi.UpdatePassword({
            oldPassword: myAccountData.oldPassword,
            password: myAccountData.password
        } as UpdatePasswordParam)
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    Utility.Alert.Success(t("Save successful!"));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
            .catch((error) => {
                Utility.Alert.Error(error);
            })
            .finally(() => {
                dispatch(showLoading(false));
            })
    }

    const saveLanguage = () => {
        dispatch(showLoading(true));

        MyAccountApi.UpdateLanguage({
            lang: myAccountData.lang
        } as UpdateLanguageParam)
            .then((response: BaseResponse<string>) => {
                if (response.is_success) {
                    Utility.Alert.Success(t("Save successful!"));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
            .catch((error) => {
                Utility.Alert.Error(error);
            })
            .finally(() => {
                dispatch(showLoading(false));
            })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <div className='account-update-password-title'>
                        {t("Update Password")}
                    </div>
                    <TextField
                        type="Password"
                        label={t("Old Password")}
                        variant='outlined'
                        className='my-account-textfield'
                        onChange={(event) => {
                            dispatch(setOldPassword(event.currentTarget.value));
                        }}
                    >
                    </TextField>
                    <TextField
                        type="Password"
                        label={t("New Password")}
                        variant='outlined'
                        className='my-account-textfield'
                        onChange={(event) => {
                            dispatch(setPassword(event.currentTarget.value));
                        }}
                    >
                    </TextField>
                    <TextField
                        type="Password"
                        label={t("Confirm Password")}
                        variant='outlined'
                        className='my-account-textfield'
                        onChange={(event) => {
                            dispatch(setConfirmPassword(event.currentTarget.value));
                        }}
                    >
                    </TextField>
                    <Button
                        variant='contained'
                        color="primary"
                        className='my-account-button button-success'
                        onClick={() => {
                            updatePassword();
                        }}
                    >{t("Change Password")}</Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div className='account-update-password-title'>
                        {t("Update Info")}
                    </div>
                    <Grid container>
                        <Grid item xs={12} lg={10}>
                            <Autocomplete
                                className='my-account-textfield'
                                value={getLanguage(myAccountData.lang)}
                                options={languages}
                                renderInput={(params) => <TextField variant='outlined' {...params} label={t("Language")} />}
                                getOptionLabel={(option: LanguageModel) => {
                                    return option.label
                                }}
                                disableClearable={true}
                                onChange={(event: React.ChangeEvent<{}>, value: LanguageModel | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<LanguageModel> | undefined) => {
                                    if (value) {
                                        dispatch(setLanguage(value.value))
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Button
                                variant='contained'
                                color="primary"
                                className='my-account-button button-success'
                                onClick={() => {
                                    saveLanguage();
                                }}
                            >{t("Save")}</Button>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} lg={10}>
                            <TextField
                                label={t("Phone")}
                                variant='outlined'
                                className='my-account-textfield'
                                value={myAccountData.phone}
                                onChange={(event) => {
                                    dispatch(setPhone(event.currentTarget.value));
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Button
                                variant='contained'
                                color="primary"
                                className='my-account-button button-success'
                                onClick={() => {
                                    savePhone();
                                }}
                            >{t("Save")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default MyAccount;