import { PackageDataModel } from '../../Store/models/PackageModel';
import { ProductDisplayModel } from '../../Store/models/ProductModel';
export default interface SubscriptionModel {
    index: number,
    service_id: string,
    type: string,
    display: ProductDisplayModel,
    func: PackageDataModel,
    applied_for: string[],
    valid_from: number,
    valid_to: number,
    key: string,
}

export enum PackageType{
    Subscription,
    Request
}