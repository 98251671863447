import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import './css/mySubscription.scss'
import { showLoading } from '../LoadingView/reducers/LoadingViewReducer';
import MySubscriptionsApi from './api/MySubscriptionsApi';
import BaseResponse from '../../app/BaseResponse';
import SubscriptionModel from './models/SubscriptionModel';
import { MySubscriptionsSelector, setCurrentSubscriptions, setIsDataLoaded, setSubscriptionsLeft } from './reducers/MySubscriptionsReducer';
import Utility from '../../app/utility';
import _ from 'lodash';
import DatatableColumnModel, { DatatableColumnAlign } from '../Datatable/DatatableColumnModel';
import Datatable, { DatatableText, PagingTypeEnum } from '../Datatable/Datatable';
import { userProfileSelector } from '../Login/reducers/UserProfileReducer';
import { LinearProgress } from '@material-ui/core';
import ProductDetail from '../Store/ProductDetail';
import { ProductDetailSelector, setProductDetailOpen, setSelectedProduct } from '../Store/reducers/ProductDetailReducer';
import { ProductDisplayModel, ProductModel, ProductType, ProductValidModel } from '../Store/models/ProductModel';

const MySubscriptions: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mySubscriptionSelector = useSelector(MySubscriptionsSelector);
    const useProfile = useSelector(userProfileSelector);
    const pageSize = 10;
    const productDetail = useSelector(ProductDetailSelector);

    useEffect(() => {
        document.title = t("My Packages");
        dispatch(showLoading(true));
        loadNotifications();
    }, []);

    const loadNotifications = () => {
        MySubscriptionsApi.GetMySubscriptions()
            .then((response: BaseResponse<SubscriptionModel[]>) => {
                if (response.is_success) {
                    let [currentSubscriptions, subscriptionsLeft] = _.chunk(response.data, pageSize);
                    if (!currentSubscriptions) {
                        currentSubscriptions = [];
                    }

                    if (!subscriptionsLeft) {
                        subscriptionsLeft = [];
                    }

                    mapTableIndex(currentSubscriptions);
                    dispatch(setCurrentSubscriptions(currentSubscriptions));
                    dispatch(setSubscriptionsLeft(subscriptionsLeft));

                    dispatch(showLoading(false));
                    dispatch(setIsDataLoaded(true));
                } else {
                    Utility.Alert.Error(response.exception);
                }
            })
    };

    const mapTableIndex = (models: SubscriptionModel[]) => {
        let index = 1;
        models.forEach(item => {
            item.index = index;
            index++;
        });
    };

    const getServiceTypeText = (service_id: string) => {
        if (service_id.indexOf("coupon") === 0) {
            return t("Coupon");
        }

        if (service_id.indexOf("package") === 0) {
            return t("Package");
        }

        return t("Gift");
    }

    const getProductType = (service_id: string) => {
        if (service_id.indexOf("coupon") === 0) {
            return ProductType.Coupon;
        }

        if (service_id.indexOf("package") === 0) {
            return ProductType.Package;
        }

        return ProductType.Gift;
    }

    const viewDetail = (rowData: SubscriptionModel) => {
        dispatch(setProductDetailOpen(true));

        let productType = getProductType(rowData.service_id);
        if (productType === ProductType.Coupon || productType === ProductType.Package) {
            let product = {
                id: rowData.service_id,
                display: rowData.display as ProductDisplayModel,
                valid: {
                    from: rowData.valid_from,
                    to: rowData.valid_to,
                } as ProductValidModel,
            } as ProductModel;
            dispatch(setSelectedProduct(product));
        }
    }

    const datatableCollumnConfig = [
        {
            Field: "index",
            Title: "#",
            Align: DatatableColumnAlign.Center,
            Width: "20px"
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "name",
            Title: t("Package Name"),
            Align: DatatableColumnAlign.Left,
            Width: "200px",
            Render: (rowData: SubscriptionModel) => (
                <>
                    {Utility.getProductDisplay(rowData.display, "name", useProfile.Lang)}
                </>
            )
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "service_id",
            Title: t("Package Type"),
            Align: DatatableColumnAlign.Center,
            Width: "100px",
            Render: (rowData: SubscriptionModel) => (
                <>
                    {t(Utility.getServiceType(rowData.service_id))}
                </>
            )
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "valid_from",
            Title: t("Start Date"),
            Align: DatatableColumnAlign.Center,
            Width: "100px",
            Render: (rowData: SubscriptionModel) => (
                <>
                    {Utility.DateFormat.getDateDDMMYYYY(rowData.valid_from)}
                </>
            )
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "valid_to",
            Title: t("Expire Date"),
            Align: DatatableColumnAlign.Center,
            Width: "100px",
            Render: (rowData: SubscriptionModel) => (
                <>
                    {Utility.DateFormat.getDateDDMMYYYY(rowData.valid_to)}
                </>
            )
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "valid_to",
            Title: t("Status"),
            Align: DatatableColumnAlign.Center,
            Width: "250px",
            Render: (rowData: SubscriptionModel) => (
                <>
                    <LinearProgress variant="determinate" value={Utility.getCurrentStatusValue(rowData.valid_from, rowData.valid_to)} />
                </>
            )
        } as DatatableColumnModel<SubscriptionModel>,
        {
            Field: "valid_to",
            Title: t("Action"),
            Align: DatatableColumnAlign.Center,
            Width: "50px",
            Render: (rowData: SubscriptionModel) => (
                <div className="subscription-detail" onClick={o => viewDetail(rowData)}>
                    <i className="fa fa-file-text-o" aria-hidden="true"></i> &nbsp; {t("Details")}
                </div>
            )
        } as DatatableColumnModel<SubscriptionModel>,
    ];

    return (
        <>
            <ProductDetail></ProductDetail>
            {
                !productDetail.open &&
                <Datatable<SubscriptionModel>
                    useDefaultSearchBar={false}
                    pagingType={PagingTypeEnum.ShowMore}
                    data={mySubscriptionSelector.currentSubscriptions}
                    useItemPerPage={false}
                    columns={datatableCollumnConfig}
                    onShowMoreClick={() => {
                        let [nextPageSubscriptions, subscriptionsLeft] = _.chunk(mySubscriptionSelector.subscriptionsLeft, pageSize);
                        let currentSubscriptions = [...mySubscriptionSelector.currentSubscriptions, ...nextPageSubscriptions];

                        currentSubscriptions = Utility.CloneObject(currentSubscriptions);

                        mapTableIndex(currentSubscriptions);

                        dispatch(setCurrentSubscriptions(currentSubscriptions));
                        dispatch(setSubscriptionsLeft(subscriptionsLeft));
                    }}
                    customText={{
                        showMoreButtonText: t("Load More")
                    } as DatatableText}
                    isDataLoaded={mySubscriptionSelector.isDataLoaded}
                    showMoreMode_IsLastPage={!mySubscriptionSelector.subscriptionsLeft || mySubscriptionSelector.subscriptionsLeft.length === 0}
                ></Datatable>
            }
        </>
    )
};

export default MySubscriptions;